<template>
<div class="main home">
    <div class="home-box">
        <h1>价格项目</h1>
        <div class="home-content">
            <!-- 查询条件 -->
            <search-item :searchInfo.sync="info" @searchList="searchList" @addTemplate="addTemplate"></search-item>
            <!-- 表格 -->
            <div class="table_container">
                <el-table size="mini" border :data="tableData" stripe v-loading="tableLoading" style="width: 100%" align='center'>
                    <el-table-column prop="projectType" label="项目分类" align='center'>
                        <template slot-scope='scope'>
                                {{ scope.row.projectType == 1 ? '水费项目': '其他项目' }}
                            </template>
                    </el-table-column>
                    <el-table-column prop="projectName" label="项目名称" align='center'>
                    </el-table-column>
                    <el-table-column prop="chargeType" label="收费类型" align='center'>
                        <template slot-scope='scope'>
                                {{ scope.row.chargeType == 1 ? '收费项目': '免费项目' }}
                            </template>
                    </el-table-column>
                    <el-table-column prop="projectAmount" label="单价(元)" align='center'>
                    </el-table-column>
                    <el-table-column prop="status" label="状态" align='center'>
                        <template slot-scope='scope'>
                                {{ scope.row.status == 0 ? '启用': '停止' }}
                            </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间" sortable align='center'>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" align='center'>
                    </el-table-column>
                    <!-- <el-table-column width="140px" fixed="right" label="操作" align='center'>
                        <template slot-scope='scope'>
                            <el-button type="text" size="mini" @click='sendCommand(scope.row)'>编辑</el-button>
                            <el-button type="text" size="mini" @click='modifyEnpr(scope.row)'>删除</el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
                <pagination :paginationInfo="paginationInfo" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
            </div>
        </div>
    </div>
    <el-dialog :modal-append-to-body='false' title="新建/修改" :visible.sync="modifyEnprVisible" width="27%">
        <el-form ref="form" label-width="120px">
            <el-form-item label="项目分类" required>
                <el-select style="width:240px;" v-model="projectType" placeholder="请选择项目分类" clearable >
                    <el-option v-for="item in xmflData" :key="item.id" :label="item.xmflmc" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="项目名称" required>
                <el-input v-model="projectName" style="width:240px;" clearable  placeholder="请输入项目名称"></el-input>
            </el-form-item>
            <el-form-item label="收费类型" required>
                <el-select style="width:240px;" v-model="chargeType" filterable clearable placeholder="请选择收费类型">
                    <el-option v-for="item in sflxData" :key="item.id" :label="item.sflxmc" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="项目单价" required>
                <el-input v-model="projectAmount" type="number" style="width:240px;" clearable placeholder="请输入项目单价"></el-input>
            </el-form-item>
            <el-form-item label="状态" required>
                <el-select style="width:240px;" v-model="status" filterable clearable placeholder="请选择收费类型">
                    <el-option v-for="item in qdztData" :key="item.id" :label="item.qdztname" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="remark" type="text" style="width:240px;" placeholder="请填写备注" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="success" size="mini" plain :loading="enprLoading" @click="onSubmit">提交</el-button>
                <el-button size="mini" @click="closeEnprDialog">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    <el-dialog :modal-append-to-body='false' title="发送指令" :visible.sync="commandVisible" width="25%">
        <el-form ref="form" label-width="120px">
            <el-form-item label="指令选择">
                <el-radio v-model="command" label="0">开阀</el-radio>
                <el-radio v-model="command" label="1">关阀</el-radio>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :loading="commandLoading" @click="onSubmit">提交</el-button>
                <el-button @click="closeCommandDialog">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</div>
</template>

<script>
import Pagination from "@/components/pagination";
import SearchItem from "./searchItem";
export default {
    data() {
        return {
            // 表格loading
            tableLoading: false,
            tableData: [], //表格数据
            paginationInfo: {
                pageIndex: 1, // 当前位于哪页
                pageTotal: 8, //表格总页数
                pageSize: 10, //每页显示条数
                pageSizes: [5, 10, 15, 20], //每页显示个数选择器的选项设置
                layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
            },
            pidList: [],
            yxqVisible: false,
            registerId: "",
            info: {
                projectType: "",
                projectName: "",
                chargeType: "",
                status: ""
            },
            commandVisible: false,
            command: '0',
            modifyEnprVisible: false,
            nowEnpr: '',
            nowDeviceId: '',
            newEnpr: '',
            arrEnpr: [],
            selectedRow: '',
            enprLoading: false,
            commandLoading: false,
            projectType: "",
            projectName: "",
            chargeType: "",
            projectAmount: null,
            status: "",
            remark: "",
            xmflData:[
                {
                    xmflmc: "水费项目",
                    id: 1
                }
            ],
            sflxData:[
                {
                    sflxmc: "收费项目",
                    id: 1
                },
                {
                    sflxmc: "免费项目",
                    id: 2
                }
            ],
            qdztData:[
                {
                    qdztname: "启动",
                    id: 1
                },
                {
                    qdztname: "停止",
                    id: 2
                }
            ]
        }
    },
    mounted() {
        let that = this
        this.getJGXMTemplateList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
    },
    methods: {
        //  获取表格信息
        getJGXMTemplateList(currpage, pageSize, info) {
            console.log(info)
            this.tableLoading = true;
            let params = {
                pageNum: currpage,
                pageSize: pageSize,
                projectType: info.projectType,
                projectName: info.projectName,
                chargeType: info.chargeType,
                status: info.status
            }
            this.tableData = []
            this.$api.jgxmTemplateList(params).then(res => {
                console.log('000', res.data.list)
                this.tableLoading = false;
                if (res && res.code == 200) {
                    this.paginationInfo.pageTotal = parseInt(res.data.total)
                    this.paginationInfo.pageIndex = res.data.pageNum
                    this.tableData = res.data.list
                }
            }).catch(error => {
                console.log(error)
                this.tableLoading = false;
            })
        },
        searchList(e) {
            console.log(e)
            this.info = e
            this.getJGXMTemplateList(1, this.paginationInfo.pageSize, e)
        },
        // 上下分页
        handleCurrentChange(val) {
            // console.log('上下分页',val)
            let that = this
            this.paginationInfo.pageIndex = val
            this.getJGXMTemplateList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        // 每页显示多少条
        handleSizeChange(val) {
            // console.log('每页显示多少条',val)
            let that = this
            this.paginationInfo.pageSize = val
            this.getJGXMTemplateList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        addTemplate(){
            this.modifyEnprVisible = true
        },
        goEdit(e) {
            console.log(e)
            this.$router.push({
                path: '/',
                query: {
                    id: e.courseId
                }
            })
        },
        submitDialog(e) {
            this.registerId = e.registerId
            this.centerDialogVisible = true
        },
        onSubmit() {
            let params = {
                projectType: this.projectType,
                projectName: this.projectName,
                chargeType: this.chargeType,
                projectAmount: this.projectAmount,
                status: this.status,
                remark: this.remark
            }
            this.commandLoading = true
            this.$api.savejgxmTemplate(params).then(res => {
                if (res && res.code === 200) {
                    this.commandVisible = false
                    this.commandLoading = false
                    this.$message({
                        message: '添加价格项目成功',
                        type: 'success'
                    })
                    this.getJGXMTemplateList(this.paginationInfo.pageIndex, this.paginationInfo.pageSize, this.info)
                } else {
                    this.commandLoading = false
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        closeCommandDialog() {
            this.commandVisible = false
        },
        closeEnprDialog() {
            this.modifyEnprVisible = false
            this.newEnpr = ''
        },
        //更新设备所在的水司
        updateEnprSumit() {
            if (this.newEnpr == '') {
                this.$message.warning('请选择新的水司')
                return
            }
            this.enprLoading = true
            this.$api.updateEnpr({
                id: this.nowDeviceId,
                enterpriseId: this.newEnpr
            }).then(res => {
                if (res.code === 0) {
                    this.$message.success('修改水司成功')
                    this.getJGXMTemplateList(this.paginationInfo.pageIndex, this.paginationInfo.pageSize, this.info)
                    this.modifyEnprVisible = false
                    this.enprLoading = false
                    this.newEnpr = ''
                } else {
                    this.enprLoading = false
                    this.$message.error('修改水司失败')
                }
            })
        },
        //启用或停用设备
        stateControl(e) {
            let state = e === 0 ? '停用' : '启用'
            this.$confirm(`确定要${state}该设备吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

            })
        },
        del(e) {
            console.log(e)
            let that = this
            this.$confirm('确定要删除该设备吗?删除后将无法恢复，需重新注册', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.delDevice({
                    id: e.id
                }).then(res => {
                    if (res.code === 0) {
                        this.getJGXMTemplateList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: '删除失败'
                        });
                    }
                })
            }).catch(() => {});
        },
        //发送指令弹框
        sendCommand(e) {
            this.commandVisible = true
            this.selectedRow = e
        },
        //修改水司弹框
        modifyEnpr(e) {
            console.log(e)
            this.nowEnpr = e.enterpriseName
            this.nowDeviceId = e.id
            this.modifyEnprVisible = true
        }
    },
    components: {
        Pagination,
        SearchItem
    }
}
</script>

<style>
/* 标题 */
.menu .title1 img {
    top: 22px;
}

.menu .title1 h3 {
    font-size: 20px;
    margin-top: -14px;
}

/* 分页 */
.el-pagination button,
.el-pagination span:not([class*=suffix]) {
    padding: 0 10px;
}

.home .block-title {
    width: 100%;
    padding: 0 0 28px 20px;
    display: flex;
    align-items: center;
}

.home .block-title h3 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .a-text {
    text-decoration: underline;
    color: #1e61ce;
}

.home .block-title .btn {
    width: 180px;
    height: 35px;
    background-color: #1e61ce;
    border-radius: 20px;
    margin-left: 20px;
    font-weight: normal;
    font-stretch: normal;
    color: #ffffff;
    padding: 0;
}

.home .btn span {
    font-size: 16px !important;
    color: #fff !important;
}

.home .block-title span {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #63717A;
}

.home .top {
    width: 100%;
    padding: 0 0 10px 0;
    border-radius: 10px;
    background: #fefefe;
}

.home .top .title {
    width: 100%;
    height: 90px;
    line-height: 90px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Semibold;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
    border-bottom: 1px solid #f2f3f5;
}

.home .el-col {
    border-radius: 10px;
}

.home .grid-content {
    border-radius: 10px;
    /* min-height: 36px; */
}

.home .leftInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.home .leftInfo .headPic {
    width: 165px;
    height: 165px;
    border-radius: 100%;
    border: 8px solid #fff;
    position: relative;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0px 12px 30px 0px rgba(6, 37, 117, 0.12);
}

.home .leftInfo .headPic img {
    width: 100%;

}

.home .rightInfo {
    margin-top: 10px;
    padding-right: 10px;
}

.home .rightInfo .name-text {

    margin-bottom: 20px;
    font-family: PingFangSC-Semibold;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .rightInfo .info-text img {
    width: 26px;
    height: 26px;
    margin-right: 20px;
}

.home .rightInfo .info-text {
    margin-bottom: 20px;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    letter-spacing: 0px;
    color: #63717a;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-align: left
}

.home .rightInfo .info-text h1 {
    font-size: 20px;
    font-weight: 600;
    font-family: PingFangSC-Medium;
    color: #2d3039;
    margin-left: 10px;
    display: block;
    font-size: 90.5%;
}

.home .middle {
    width: 100%;
    border-radius: 10px;
}

.home .middle .grid-content {
    background: #fff
}

.home .title1 {
    width: 100%;
    display: flex;
    padding: 0 20px 20px 20px;
    align-items: center;
    margin-top: -10px;
    border-bottom: 1px solid #f2f3f5;
}

.home .title1 h3 {
    font-size: 20px;
    margin-top: -10px;
}

.home .title1 img {
    position: relative;
    top: 18px;
    margin-right: 10px;
}

.home .ajs-Title {
    margin-left: 20px;
}

.home .ajs {
    margin-left: 20px;
    color: #0033cf;
}

.home .left-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    /* border-right: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8; */
}

.home .right-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.home .main-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

/*案件办理天数 标签统计 hmm 090403*/
.home .labelModule {
    background: #F2F5FA;
    border: 1px solid #D6E0F0;
    border-radius: 10px;
    display: inline-block;
    width: 100%;
    padding: 20px;
}

.home .labelModule:first-child {
    margin-bottom: 20px;
}

.home .labelModule ul li {
    width: 100%;
    font-size: 16px;
    color: #63717A;
    text-align: center
}

.home .labelModule ul li:first-child {
    margin-bottom: 10px;
}

.home .labelModule ul li font {
    font-size: 41px;
    color: #2D3039;
}

/*公共部分 hmm*/
.p20 {
    padding: 20px;
}

.pb30 {
    padding-bottom: 30px;
}

/*办案类型 数据超出 显示样式 hmm 090404*/

.widthBig .labelModule {
    width: 49.2%;
}

.widthBig .labelModule:first-child {
    margin-bottom: 30px;
    margin-right: 20px;
}

.widthSmall {
    float: right;
}

.openstate {
    color: #8CC663;
}

.stopstate {
    color: #e92d0c;
}
</style>
