<template>
<div class="search_container searchArea">
    <el-form :inline="true" :model='searchInfo' ref="searchInfo" :rules="rules" :label-position="labelPosition" class="demo-form-inline search-form">
        <div class="search_form_item">
            <el-form-item prop="name" label="项目分类">
                <el-select v-model="searchInfo.projectType" placeholder="" clearable >
                    <el-option v-for="item in xmflData" :key="item.id" :label="item.xmflmc" :value="item.id" @change="projectTypeChange"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="name" label="项目名称">
                <el-input v-model="searchInfo.projectName" @change="projectNameChange" clearable></el-input>
            </el-form-item>
            <el-form-item prop="name" label="收费类型">
                <el-select v-model="searchInfo.chargeType" placeholder="" clearable >
                    <el-option v-for="item in sflxData" :key="item.id" :label="item.sflxmc" :value="item.id" @change="chargeTypeChange"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="name" label="启用状态">
                <el-select v-model="searchInfo.status" placeholder="" clearable >
                    <el-option v-for="item in qdztData" :key="item.id" :label="item.qdztname" :value="item.id" @change="statusChange"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="search-btn">
                <el-button type="success" size="mini" icon="el-icon-search" plain @click="searchList()">查询</el-button>
                <!-- <el-button size="mini" icon="el-icon-refresh-left" plain @click="addKC()">重置</el-button> -->
                <el-button type="success" size="mini" icon="el-icon-plus" plain @click="addKC()">新建</el-button>
            </el-form-item>
        </div>
    </el-form>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

export default {
    name: 'searchItem',
    props: {
        searchInfo: {
            type: Object,
            required: true
        }

    },
    data() {
        return {
            labelPosition: 'left',
            rules: {},
            xmflData:[
                {
                    xmflmc: "水费项目",
                    id: 1
                }
            ],
            sflxData:[
                {
                    sflxmc: "收费项目",
                    id: 1
                },
                {
                    sflxmc: "免费项目",
                    id: 2
                }
            ],
            qdztData:[
                {
                    qdztname: "启动",
                    id: 1
                },
                {
                    qdztname: "停止",
                    id: 2
                }
            ]
        }
    },
    computed: {},
    created() {},
    methods: {
        // 查询
        searchList() {
            this.$emit('searchList', this.searchInfo)
        },
        addKC() {
            this.$emit('addTemplate')
        },
        //有效期设置
        yxqsz() {
            this.$emit('yxqSetting')
        },
        projectTypeChange(e){
            this.searchInfo.projectType = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        projectNameChange(e){
            this.searchInfo.projectName = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        chargeTypeChange(e) {
            this.searchInfo.chargeType = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        statusChange(e) {
            this.searchInfo.status = e
            this.$emit('update:searchInfo', this.searchInfo)
        }
    }
}
</script>

<style>
.search_container {
    padding: 20px 0 0px;
    /* border-bottom: 1px solid #eff1f4; */
}
</style>
